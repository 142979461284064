import { IntegrationAgentEntry, integrationAgents } from "@constants";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { ChangeEvent, FC } from "react";

type OverrideItem = Omit<IntegrationAgentEntry, "value"> & {
  disabled?: boolean;
  value: string;
};

export const IntegrationAgentsNameSelector: FC<{
  value: string;
  onChange: (event: ChangeEvent<any>) => void;
  itemsOverride?: OverrideItem[];
  disabled?: boolean;
}> = ({ value, onChange, itemsOverride = integrationAgents, disabled }) => {
  return (
    <FormControl required variant="outlined" className="text-field">
      <InputLabel id="integrationAgentName">Service Name</InputLabel>
      <Select
        labelId="integrationAgentName"
        value={value}
        onChange={onChange}
        label="Service Name"
        name="integrationAgentName"
        disabled={disabled}
      >
        {itemsOverride.map((item: OverrideItem) => {
          return (
            <MenuItem
              value={item.value}
              disabled={"disabled" in item ? item.disabled : false}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
